import React, { useEffect, useState, useRef, useCallback } from "react";
import {
	Editor,
	EditorState,
	RichUtils,
	getDefaultKeyBinding,
	convertToRaw,
	convertFromRaw
} from "draft-js";
import "./example.css";
import "./draft.css";
import "./index.css";

function RichEditorExample({ Description, setDescription, productId }) {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const editor = useRef(null);

	useEffect(() => {
		// Save the editor content in JSON format to Description
		setDescription(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
	}, [editorState, setDescription]);

	useEffect(() => {
		// Initialize editor content with Description if it exists and is in JSON format
		if (productId && Description) {
			try {
				const parsedDescription = Description.startsWith("{")
					? JSON.parse(Description)
					: null;
				if (parsedDescription) {
					setEditorState(EditorState.createWithContent(convertFromRaw(parsedDescription)));
				}
			} catch (error) {
				console.error("Failed to parse Description:", error);
			}
		}
	}, [Description, productId]);

	const focus = () => editor.current?.focus();

	const handleKeyCommand = useCallback(
		(command, editorState) => {
			const newState = RichUtils.handleKeyCommand(editorState, command);
			if (newState) {
				setEditorState(newState);
				return "handled";
			}
			return "not-handled";
		},
		[setEditorState]
	);

	const mapKeyToEditorCommand = useCallback(
		(e) => {
			if (e.keyCode === 9) { // TAB key
				const newEditorState = RichUtils.onTab(e, editorState, 4);
				if (newEditorState !== editorState) {
					setEditorState(newEditorState);
				}
				return null;
			}
			return getDefaultKeyBinding(e);
		},
		[editorState, setEditorState]
	);

	const className = `RichEditor-editor ${
		!editorState.getCurrentContent().hasText() &&
		editorState.getCurrentContent().getBlockMap().first().getType() !== "unstyled"
			? "RichEditor-hidePlaceholder"
			: ""
	}`;

	return (
		<div className="RichEditor-root">
			<BlockStyleControls
				editorState={editorState}
				onToggle={(blockType) => {
					setEditorState(RichUtils.toggleBlockType(editorState, blockType));
				}}
			/>
			<InlineStyleControls
				editorState={editorState}
				onToggle={(inlineStyle) => {
					setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
				}}
			/>
			<div className={className} onClick={focus}>
				<Editor
					blockStyleFn={getBlockStyle}
					customStyleMap={styleMap}
					editorState={editorState}
					handleKeyCommand={handleKeyCommand}
					keyBindingFn={mapKeyToEditorCommand}
					onChange={setEditorState}
					placeholder="Product Description ..."
					ref={editor}
					spellCheck={true}
				/>
			</div>
		</div>
	);
}

// Custom styles and helper functions
const styleMap = {
	CODE: {
		backgroundColor: "rgba(0, 0, 0, 0.05)",
		fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
		fontSize: 16,
		padding: 2
	}
};

function getBlockStyle(block) {
	return block.getType() === "blockquote" ? "RichEditor-blockquote" : null;
}

function StyleButton({ onToggle, active, label, style }) {
	let className = "RichEditor-styleButton";
	if (active) {
		className += " RichEditor-activeButton";
	}

	return (
		<span
			className={className}
			onMouseDown={(e) => {
				e.preventDefault();
				onToggle(style);
			}}
		>
			{label}
		</span>
	);
}

const BLOCK_TYPES = [
	{ label: "H1", style: "header-one" },
	{ label: "H2", style: "header-two" },
	// Additional block types...
];

function BlockStyleControls({ editorState, onToggle }) {
	const selection = editorState.getSelection();
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType();

	return (
		<div className="RichEditor-controls">
			{BLOCK_TYPES.map((type) => (
				<StyleButton
					key={type.label}
					active={type.style === blockType}
					label={type.label}
					onToggle={onToggle}
					style={type.style}
				/>
			))}
		</div>
	);
}

const INLINE_STYLES = [
	{ label: "Bold", style: "BOLD" },
	{ label: "Italic", style: "ITALIC" },
	// Additional inline styles...
];

function InlineStyleControls({ editorState, onToggle }) {
	const currentStyle = editorState.getCurrentInlineStyle();
	return (
		<div className="RichEditor-controls">
			{INLINE_STYLES.map((type) => (
				<StyleButton
					key={type.label}
					active={currentStyle.has(type.style)}
					label={type.label}
					onToggle={onToggle}
					style={type.style}
				/>
			))}
		</div>
	);
}

export default RichEditorExample;
