import { Box, Container, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { Select as AntdSelect, Steps, Tag } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import componentStyles from "../../../assets/theme/views/admin/profile";
import UserHeader from "../../../components/Headers/UserHeader";
import { withAppContext } from "../../../contextApi/Auth";
import { BACKEND_URL, BACKENDImage_URL } from "../../../env";
import "../Drag.css";
import StepOne from "./Stepone";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
const useStyles = makeStyles(componentStyles);
// import { webp } from 'webp-converter';
const { Option } = AntdSelect;
const steps = [
    {
        title: 'Add Product Details',
        content: 'First-content',
    },
    {
        title: 'Add Video',
        content: 'Second-content',
    },
    {
        title: 'Add Images',
        content: 'Last-content',
    },
];

const fileTypes = ["JPEG", "jpg", "png", "webp", "MP4", "jfif"];

function CreateProduct({
    AllCategory,
    getTags,
    TagsData,
    getSize,
    getColor,
    ...props
}) {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [current, setCurrent] = useState(0);
    const [productId, setProductId] = useState("");
    const [Name, setName] = useState("");
    const [sku, setSku] = useState("");
    const [Price, setPrice] = useState(0);
    const [Description, setDescription] = useState("");
    const [Category, setCategory] = useState("");
    const [SubCategory, setSubCategory] = useState("");
    const [Tags, setTags] = useState([]);
    const [AllSubCategoriesByCategory, setAllSubCategoriesByCategory] = useState([]);
    const [stockStatus, setStockStatus] = useState();
    const [bestSelling, setBestSelling] = useState();
    const [featureProduct, setFeatureProduct] = useState();
    const [value, setValue] = useState(0);
    const [imageUpload, setImageUpload] = useState([]);
    const [allImage, setAllImage] = useState([]);
    const [videoUpload, setVideoUpload] = useState([]);
    const [allVideo, setAllVideo] = useState([]);
    const items = steps?.map((item) => ({ key: item.title, title: item.title }));
    const history = useHistory();
    useEffect(() => {
        let url = new URL(window.location);
        let id = url.searchParams.get("id");
        getTags();
        if (id) {
            setProductId(id);
            GetProductInfo(id);
        }
    }, []);

    function GetProductInfo(id) {
        axios
            .get(`${BACKEND_URL}/products/${id}`, {
                headers: {
                    token: localStorage.getItem("Token"),
                },
            })
            .then((res) => {
                const arr = [];
                console.log(res.data.getproduct, "res.data");
                if (res.data.getproduct) {
                    let obj = res.data.getproduct;
                    setName(obj?.title);
                    setPrice(obj?.price);
                    setSku(obj?.sku);
                    const parsedDescription = obj?.description.startsWith('{')
                        ? JSON.parse(obj?.description)
                        : obj?.description;
                    console.log(parsedDescription);
                    console.log(obj?.description);

                    setDescription(parsedDescription);
                    setCategory(obj?.category);
                    // setSubCategory(obj?.SubCategory);
                    // setTags(obj?.tag);
                    setStockStatus(obj?.stock);
                    setBestSelling(obj?.bestSelling);
                    setFeatureProduct(obj?.FeatureProduct);
                    updateImageArr(obj?.images);
                    updateVideoArr(obj?.videos);

                }
            })
            .catch((res) => console.log(res));
    }
    function toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
    }
    console.log(videoUpload);

    const updateImageArr = (imagesArr) => {
        let arrayImage = [];
        imagesArr?.map((a, i) => {
            let iamgUrl = "";
            toDataURL(`${BACKENDImage_URL}/${a}`, (url) => {
                console.log(url, "urlurlurlurlurlurlurlurlurl");
                iamgUrl = url;
                // arrayImage.push(url);
                arrayImage.push({
                    image: url,
                    type: a?.type,
                });

                if (i === imagesArr.length - 1) {
                    setImageUpload(arrayImage);
                }
                // })
            });
            // setImageUpload([
            //   ...imageUpload,
            //   {
            //     image: iamgUrl,
            //     color: a?.itemColor,
            //   },
            // ]);
            // let newArr = arrayImage;
            // console.log(arrayImage, "imageUpload");
        });
    };
    const updateVideoArr = (videosArr) => {
        let arrayVideo = [];
        videosArr?.map((a, i) => {
            let iamgUrl = "";
            toDataURL(`${BACKENDImage_URL}/${a}`, (url) => {
                console.log(url, "urlurlurlurlurlurlurlurlurl");
                iamgUrl = url;
                // arrayImage.push(url);
                arrayVideo.push({
                    video: url,
                    type: a?.type,
                });

                if (i === arrayVideo.length - 1) {
                    setVideoUpload(arrayVideo);
                }
                // })
            });
            // setImageUpload([
            //   ...imageUpload,
            //   {
            //     image: iamgUrl,
            //     color: a?.itemColor,
            //   },
            // ]);
            // let newArr = arrayImage;
            // console.log(arrayImage, "imageUpload");
        });
    };
    const handleChangeTagsSelect = (value) => {
        // setTags([...value]);
        setTags(Array.isArray(value) ? value : [value]);
    };

    const addProductDetail = () => {
        if (Name) {
            setLoading(true);

            // Create a new FormData object
            const formData = new FormData();
            formData.append('title', Name);
            formData.append('price', Price);
            formData.append('sku', sku);
            Description.length ? formData.append('description', Description || "") : null

            formData.append('category', Category);
            formData.append('subcategory', SubCategory || "none");
            formData.append('stock', stockStatus || "");
            formData.append('bestSelling', bestSelling || false);
            formData.append('FeatureProduct', featureProduct || false);

            // Append tags array correctly
            Tags.forEach(tag => formData.append('tag', tag)); // Note: 'tags' is recommended for array inputs
            // formData.append('tag', Tags);
            // Append images correctly
            allImage.forEach(image => {
                formData.append('images', image); // Assuming 'images' is expected for array inputs
            });
            allVideo.forEach(video => {
                formData.append('videos', video); // Assuming 'videos' is expected for array inputs
            });

            const config = {
                method: productId ? "put" : "post",
                url: productId
                    ? `${BACKEND_URL}/products/${productId}`
                    : `${BACKEND_URL}/products`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: localStorage.getItem("Token"),
                },
            };

            // Make the axios request
            axios(config)
                .then((e) => {
                    setName("");
                    setPrice("");
                    setDescription("");
                    setCategory("");
                    setSubCategory("");
                    setTags([]);
                    setStockStatus("");
                    setBestSelling(false);
                    setFeatureProduct(false);
                    setImageUpload([]);
                    setAllImage([]);
                    setSku("")
                    setVideoUpload([])
                    setAllVideo([])
                    setCurrent(0);
                    toast.success(e?.data?.message || (productId ? "Product updated successfully" : "Product added successfully"));
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    setLoading(false);
                    toast.error(err?.response?.data?.message || "An error occurred");
                });
        } else {
            setLoading(false);
            toast.error("Please fill in all required fields");
        }
    };

    const nextStep = () => {
        if (current === 0) {

            // if (!Name  || !Tags || !Price || !stockStatus || !Description) {
            //     return toast.error(
            //         `${!Name.length
            //             ? "Please Fill Name Field"
            //             : // :
            //             // !Price.length
            //             // ? "Please Fill Price Field"
            //             !Description.length
            //                 ? "Please Fill Description Field"
            //                 : !Category.length
            //                     ? "Please Fill Category Field"
            //                     : !Tags.length
            //                         ? "Please Fill Tags Field"
            //                         : !Price.length
            //                             ? "Please Fill Price Field"
            //                             : !stockStatus.length
            //                                 ? "Please Fill Stock Field"
            //                                 : "Invalid"
            //         }`,
            //         {
            //             position: "top-right",
            //             autoClose: 3000,
            //             hideProgressBar: true,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: false,
            //         }
            //     );
            // }
            setCurrent(1)
        } else {
            setCurrent(2)
        }
    }
    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(async () => {

        AllCategory.filter((a) => {
            if (a.name == Category) {
                // console.log("All cat aaa   ------->", a.name, Category);
                setAllSubCategoriesByCategory(a.SubCategory);
            }
        });
    }, [Category]);
    const madeImage = async (file) => {
        setAllImage((prevImages) => [...prevImages, ...file]);

        let arrImages = [...imageUpload];
        Object.values(file)?.map(async (ev, i) => {
            var reader = new FileReader();
            reader.readAsDataURL(ev);
            reader.onload = function () {
                console.log(Object.values(file)?.length);
                console.log(i);
                arrImages.push({
                    image: reader.result,
                    type: ev?.type,
                });
                // }
                if (Object.values(file)?.length === i + 1) {
                    setImageUpload(arrImages);
                }
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        });
    };
    const madeVideo = async (file) => {
        console.log(file);

        setAllVideo((prevImages) => [...prevImages, ...file]);

        let arrVideos = [...videoUpload];
        Object.values(file)?.map(async (ev, i) => {
            var reader = new FileReader();
            reader.readAsDataURL(ev);
            reader.onload = function () {
                console.log(Object.values(file)?.length);
                console.log(i);
                arrVideos.push({
                    video: reader.result,
                    type: ev?.type,
                });
                // }
                if (Object.values(file)?.length === i + 1) {
                    setVideoUpload(arrVideos);
                }
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        });
    };
    return (
        <>
            <UserHeader />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        xl={12}
                        component={Box}
                        marginBottom="3rem"
                        classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
                    >
                        <Card
                            classes={{
                                root: classes.cardRoot + " " + classes.cardRootSecondary,
                            }}
                        >
                            <div style={{
                                padding: "21px 20px 20px 21px",
                                background: "#f7fafc",
                                borderRadius: "10px 10px 0px 10px"
                            }}>
                                <Steps current={current} items={items} />
                            </div>
                            {current === 0 ?
                                <StepOne
                                    classes={classes}
                                    setSku={setSku} sku={sku}
                                    setName={setName} Name={Name} setPrice={setPrice}
                                    TagsData={TagsData}
                                    Price={Price} AllCategory={AllCategory}
                                    setCategory={setCategory} Category={Category}
                                    setSubCategory={setSubCategory} SubCategory={SubCategory}
                                    AllSubCategoriesByCategory={AllSubCategoriesByCategory}
                                    Tags={Tags}
                                    handleChangeTagsSelect={handleChangeTagsSelect}
                                    productId={productId}
                                    setDescription={setDescription}
                                    Description={Description}
                                    addProductDetail={nextStep}
                                    loading={loading}
                                    setStockStatus={setStockStatus}
                                    stockStatus={stockStatus}
                                    setBestSelling={setBestSelling}
                                    bestSelling={bestSelling}
                                    setFeatureProduct={setFeatureProduct}
                                    featureProduct={featureProduct}
                                />

                                : current === 1 ?
                                    <StepTwo
                                        allVideo={allVideo}
                                        setAllVideo={setAllVideo}
                                        classes={classes}
                                        value={value}
                                        videoUpload={videoUpload}
                                        setVideoUpload={setVideoUpload}
                                        fileTypes={fileTypes}
                                        loading={loading}
                                        madeVideo={madeVideo}
                                        addProductVideos={nextStep}
                                    />
                                    : current === 2 ?
                                        <StepThree
                                            allImage={allImage}
                                            setAllImage={setAllImage}
                                            classes={classes}
                                            value={value}
                                            imageUpload={imageUpload}
                                            setImageUpload={setImageUpload}
                                            fileTypes={fileTypes}
                                            loading={loading}
                                            madeImage={madeImage}
                                            addProductImages={addProductDetail}
                                        /> : null
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Container >

        </>
    );
}
export default withAppContext(CreateProduct);